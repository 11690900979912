import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as PropTypes from 'prop-types'
import { navigate, useIntl as useIntlSource } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

import { withMemo, withCountdown } from '../../decorators'
import Layout from '../../components/Layout'
import useConfiguration from '../../hooks/useConfiguration'
import HomeTemplate from '../../templates/Home'
import { useIntl } from '../../intl/intl'
import * as appConfiguration from '../../configuration'
import router from '../../router'
import routes from '../../router/routes'


const HomeContainer = (props) => {
  const {
    pageContext,
    location,
    data,
  } = props
  const {
    seoFallback,
    configuration,
  } = useConfiguration(data)
  const t = useIntl()
  const { locale } = useIntlSource()

  // STATE

  const [isStreamEnded, setIsStreamEnded] = useState(configuration.isStreamOffline)

  // HANDLERS

  const handleOnLookBookClick = useCallback(() => {
    navigate(router(routes.looks.path))
  }, [])

  const handleOnInfoClick = useCallback(() => {
    navigate(router(routes.page.path, { slug: 'info' }))
  }, [])

  const handleOnLegalClick = useCallback(() => {
    navigate(router(routes.page.path, { slug: 'legal-notice' }))
  }, [])

  // MEMO

  const layoutProps = useMemo(() => ({
    seo: {
      lang: pageContext?.language ?? null,
      title: seoFallback?.title ?? '',
      description: seoFallback?.description?.description ?? '',
      image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
      url: location?.href ?? '',
    },
  }), [seoFallback.title, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, location.href, pageContext])

  // EFFECTS

  useEffect(() => {
    let timer

    const countdownDate = new Date(configuration?.countdown)

    const streamEndDate = new Date(countdownDate.getTime() + configuration?.streamDuration * 1000)

    const streamEndDeltaTime = streamEndDate.getTime() - Date.now()

    if (streamEndDeltaTime > 0) {
      timer = setTimeout(() => {
        setIsStreamEnded(true)
      }, streamEndDeltaTime)
    } else {
      setIsStreamEnded(true)
    }

    return () => timer && clearTimeout(timer)
  }, [configuration])

  return (
    <Layout {...layoutProps}>
      <HomeTemplate
        iframeUrl={configuration?.streamUrl}
        pressKitLabel={t('header_press_kit')}
        lookBookLabel={t('header_lookbook')}
        infoLabel={t('header_info')}
        legalLabel={t('legal_link_label')}
        onLegalClick={handleOnLegalClick}
        onLookBookClick={handleOnLookBookClick}
        onInfoClick={handleOnInfoClick}
        videoProps={{ ...t('youtube_video_id') !== 'none' ? {
          videoId: t('youtube_video_id'),
          opts: {
            width: '100%',
            height: '100%',
            host: 'http://www.youtube-nocookie.com',
            playerVars: {
              autoplay: 1,
              modestbranding: 1,
              playsinline: 1,
              showinfo: 0,
              controls: 1,
              rel: 0,
            },
          },
        } : {},
        ...t('youtube_video_id') === 'none' && t('video_iframe_url') ? {
          videoIframeUrl: t('video_iframe_url'),
        } : {},
        ...t('youtube_video_id') === 'none' && t('video_iframe_url') === 'none' && t('video_path') ? {
          videoPath: t('video_path'),
        } : {},
        }}
        passwordPopupProps={{
          inputLabel: t('press_kit_input_label'),
          buttonLabel: t('press_kit_button'),
          successLabel: t('press_kit_success'),
          errorLabel: t('press_kit_error'),
          fileName: 'press-kit.zip',
          filePath: `/press-kit-${locale}.zip`,
          checkboxLabel: t('press_kit_checkbox_label'),
        }}
        playVideoLabel={t('video_play_button')}
        isStreamEnded={isStreamEnded}
        backgroundVideoSource={configuration.videoBackground?.file}
        backgroundVideoImage={configuration.imageBackground?.localFile?.childImageSharp?.fluid}
      />
    </Layout>
  )
}

HomeContainer.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

HomeContainer.defaultProps = {
  data: null,
  pageContext: null,
  location: null,
}

export default withCountdown(withMemo()(HomeContainer))

export const query = graphql`
  query homepage($language: String){
    contentfulConfiguration(node_locale: { eq: $language }) {
      ...balenciageF21Configuration
    }
  }
`
