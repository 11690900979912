import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { gsap, Power1 } from 'gsap'

import { Icon } from '..'
import Button, { variants as buttonVariants } from '../Button'
import { iconsKeys } from '../Icon/Icon.assets'

import useStyles from './styles'

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */

const steps = {
  input: 'input',
  success: 'success',
  error: 'error',
}

const PasswordPopup = (props) => {
  const classes = useStyles(props)
  const {
    className,
    inputLabel,
    checkboxLabel,
    buttonLabel,
    successLabel,
    errorLabel,
    filePath,
    onCheckboxLinkClick,
    onClose,
  } = props

  // REFS

  const $container = useRef(null)

  // STATES

  const [inputValue, setInputValue] = useState('')
  const [step, setStep] = useState(steps.input)
  const [isChecked, setIsChecked] = useState(false)

  // MEMO

  const isPasswordValid = useMemo(() => inputValue === 'ELIZA', [inputValue])

  const checkboxLabelParts = useMemo(() => checkboxLabel.split('*'), [checkboxLabel])


  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    gsap.to($container.current, {
      duration: 0.4,
      opacity: 1,
      ease: Power1.easeIn,
    })
  }, [])

  const exitAnimation = useCallback((onCompleteCallback) => {
    gsap.to($container.current, {
      duration: 0.4,
      opacity: 0,
      ease: Power1.easeIn,
      onComplete: onCompleteCallback,
    })
  }, [])

  // HANDLERS

  const handleOnChangeInput = useCallback((e) => {
    setInputValue(e.target.value)
  }, [])

  const handleOnButtonClick = useCallback(() => {
    if (isPasswordValid) {
      setStep(steps.success)
      if (document && document.getElementById('filepath')) {
        document.getElementById('filepath').click()
      }
    } else {
      setStep(steps.error)
    }
  }, [isPasswordValid])

  const handleOnClose = useCallback(() => {
    exitAnimation(onClose)
  }, [exitAnimation, onClose])

  const handleToggleCheckbox = useCallback(() => {
    setIsChecked((prevState) => !prevState)
  }, [])

  // EFFECTS

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  // RETURN

  return (
    <div
      ref={$container}
      className={cx(classes.container, className, { success: step === steps.success })}
    >
      <div
        className={classes.wrapper}
      >
        {step === steps.input && (
          <label
            htmlFor="input-password"
            className={classes.inputlabel}
          >
            {inputLabel}
          </label>
        )}
        {step === steps.error && (
          <p className={classes.error}>{errorLabel}</p>
        )}
        {step === steps.success && (
          <p className={classes.success}>{successLabel}</p>
        )}
        {(step === steps.input || step === steps.error) && (
          <>
            <input
              id="input-password"
              type="password"
              value={inputValue}
              className={classes.input}
              onChange={handleOnChangeInput}
            />
            <div className={classes.checkboxContainer}>
              <div
                className={classes.checkbox}
                onClick={handleToggleCheckbox}
              >
                {isChecked && (
                <Icon
                  icon={iconsKeys.Check}
                  className={classes.checkboxIcon}
                />
                )}
              </div>
              <p
                className={classes.checkboxText}
                onClick={handleToggleCheckbox}
              >
                {checkboxLabelParts[0]}
                <span onClick={onCheckboxLinkClick}>{checkboxLabelParts[1]}</span>
                {checkboxLabelParts[2]}
              </p>
            </div>
            <Button
              className={cx(classes.button, { disabled: inputValue === '' || !isChecked })}
              variant={buttonVariants.BlackOnWhite}
              onClick={handleOnButtonClick}
              text={buttonLabel}
            />

            <a
              id="filepath"
              href={filePath}
            />
          </>
        )}
      </div>
      <Icon
        icon={iconsKeys.Close2}
        className={classes.close}
        onClick={handleOnClose}
      />
    </div>
  )
}

PasswordPopup.propTypes = {
  className: PropTypes.string,
  inputLabel: PropTypes.string,
  checkboxLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  successLabel: PropTypes.string,
  errorLabel: PropTypes.string,
  // eslint-disable-next-line
  fileName: PropTypes.string,
  filePath: PropTypes.string,
  onClose: PropTypes.func,
  onCheckboxLinkClick: PropTypes.func,
}

PasswordPopup.defaultProps = {
  className: null,
  inputLabel: null,
  checkboxLabel: null,
  buttonLabel: null,
  successLabel: null,
  errorLabel: null,
  fileName: null,
  filePath: null,
  onClose: () => {},
  onCheckboxLinkClick: () => {},
}

export default PasswordPopup
