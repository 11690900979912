import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { gsap, Power1 } from 'gsap'

import { EVENTS, trackEvent } from '../../utils/tracking'
import withMemo from '../../decorators/withMemo'
import { Header } from '../../components'
import Button, { variants as ButtonVariants } from '../../components/Button'
import PasswordPopup from '../../components/PasswordPopup'
import Video from '../../components/Video'
import Image from '../../components/Image'

import useStyles from './styles'


const HomeTemplate = (props) => {
  const classes = useStyles(props)
  const {
    className,
    iframeUrl,
    pressKitLabel,
    infoLabel,
    lookBookLabel,
    playVideoLabel,
    legalLabel,
    passwordPopupProps,
    videoProps,
    isStreamEnded,
    backgroundVideoImage,
    onLookBookClick,
    onInfoClick,
    onLegalClick,
  } = props

  // REFS

  const $animatedWrapper = useRef()

  // STATES

  const [showPasswordPopin, setShowPasswordPopin] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    const timeline = gsap.timeline({ delay: 0 })

    if ($animatedWrapper.current) {
      const animatedChildren = Array.from($animatedWrapper.current.children)

      timeline.fromTo(animatedChildren, {
        opacity: 0,
      }, {
        duration: 0.5,
        opacity: 1,
        stagger: 0.3,
        ease: Power1.easeIn,
      })
    }
  }, [])

  const exitAnimation = useCallback((onCompleteCallback) => {
    const timeline = gsap.timeline({ onComplete: onCompleteCallback })

    if ($animatedWrapper.current) {
      timeline.to($animatedWrapper.current, {
        duration: 0.3,
        opacity: 0,
        ease: Power1.easeIn,
      })
    }
  }, [])

  // HANDLERS

  const handleOnLookBookClick = useCallback(() => {
    exitAnimation(onLookBookClick)
  }, [exitAnimation, onLookBookClick])

  const handleOnInfoClick = useCallback(() => {
    exitAnimation(onInfoClick)
  }, [exitAnimation, onInfoClick])

  const handleOnPressKitClick = useCallback(() => {
    setShowPasswordPopin(true)
    trackEvent(EVENTS.PRESS_KIT_CLICK)
  }, [])

  const handleOnPlayVideoClick = useCallback(() => {
    setShowVideo(true)
    trackEvent(EVENTS.PLAY_VIDEO)
  }, [])

  const handleOnLegalClick = useCallback(() => {
    exitAnimation(onLegalClick)
  }, [exitAnimation, onLegalClick])

  const handleOnPasswordPopupCheckboxLinkClick = useCallback((e) => {
    e.stopPropagation()
    exitAnimation(onLegalClick)
    return false
  }, [exitAnimation, onLegalClick])

  // EFFECTS

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  // RENDERERS

  const renderStreamVideo = useMemo(() => (
    iframeUrl && (
      <div className={classes.iframeContainer}>
        <div className={classes.iframeAspectRatio}>
          <iframe
            src={iframeUrl}
            frameBorder="0"
            allowFullScreen
            allow="autoplay; fullscreen"
            title="video stream"
          />
        </div>
      </div>
    )
  ), [classes.iframeAspectRatio, classes.iframeContainer, iframeUrl])

  const renderWaitingScreen = useMemo(() => (
    <div className={classes.backgroundVideoContainer}>
      <div className={classes.waitingScreenContainer}>
        <div className={classes.waitingScreenAspectRatio}>
          <div
            className={classes.background}
            aria-hidden="true"
            onClick={handleOnPlayVideoClick}
          >
            {backgroundVideoImage && (
              <Image
                img={backgroundVideoImage}
                className={classes.waitingImage}
              />
            )}
          </div>
          <Button
            text={playVideoLabel}
            onClick={handleOnPlayVideoClick}
            variant={ButtonVariants.BlackOnWhite}
            hasBorder={false}
            className={classes.videoButton}
          />
        </div>
      </div>

    </div>
  ), [backgroundVideoImage, classes.background, classes.backgroundVideoContainer, classes.videoButton, classes.waitingImage, classes.waitingScreenAspectRatio, classes.waitingScreenContainer, handleOnPlayVideoClick, playVideoLabel])

  // RETURN

  return (
    <main className={cx(className, classes.container)}>
      <div
        ref={$animatedWrapper}
        className={classes.wrapper}
      >
        <Header
          className={classes.header}
          isMenuOpen={false}
          hasMenuToggle
          menuContent={(
            <div className={classes.headerMenu}>
              <p onClick={handleOnLookBookClick}>{lookBookLabel}</p>
              <p onClick={handleOnInfoClick}>{infoLabel}</p>
              <p onClick={handleOnPressKitClick}>{pressKitLabel}</p>
            </div>
          )}
          rightContent={(
            <div className={classes.headerRight}>
              <p onClick={handleOnLookBookClick}>{lookBookLabel}</p>
              <p onClick={handleOnInfoClick}>{infoLabel}</p>
              <p onClick={handleOnPressKitClick}>{pressKitLabel}</p>
            </div>
          )}
        />
        {isStreamEnded ? renderWaitingScreen : renderStreamVideo}
      </div>
      {showPasswordPopin && (
        <PasswordPopup
          className={classes.passwordPopup}
          onClose={() => setShowPasswordPopin(false)}
          onCheckboxLinkClick={handleOnPasswordPopupCheckboxLinkClick}
          {...passwordPopupProps}
        />
      )}
      {showVideo && (
        <Video
          className={classes.video}
          onClose={() => {
            setShowVideo(false)
          }}
          {...videoProps}
        />
      )}
      <p
        className={classes.legalLink}
        onClick={handleOnLegalClick}
      >
        {legalLabel}
      </p>
    </main>
  )
}

export const HomeTemplatePropTypes = {
  className: PropTypes.string,
  iframeUrl: PropTypes.string,
  pressKitLabel: PropTypes.string,
  infoLabel: PropTypes.string,
  lookBookLabel: PropTypes.string,
  playVideoLabel: PropTypes.string,
  legalLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  videoProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  passwordPopupProps: PropTypes.object,
  isStreamEnded: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  backgroundVideoSource: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  backgroundVideoImage: PropTypes.object,
  onLookBookClick: PropTypes.func,
  onInfoClick: PropTypes.func,
  onLegalClick: PropTypes.func,
}

HomeTemplate.propTypes = HomeTemplatePropTypes

HomeTemplate.defaultProps = {
  className: null,
  iframeUrl: null,
  pressKitLabel: '',
  infoLabel: '',
  lookBookLabel: '',
  playVideoLabel: '',
  legalLabel: '',
  videoProps: {},
  passwordPopupProps: {},
  backgroundVideoSource: undefined,
  backgroundVideoImage: undefined,
  isStreamEnded: false,
  onLookBookClick: () => {
  },
  onInfoClick: () => {
  },
  onLegalClick: () => {
  },
}

export default withMemo()(HomeTemplate)
