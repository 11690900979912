/* eslint-disable jsx-a11y/media-has-caption */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'
import YouTube from 'react-youtube'
import { gsap, Power1 } from 'gsap'

import { safeCall } from '../../utils/safeCall'
import withMemo from '../../decorators/withMemo'
import Icon from '../Icon'
import { iconsKeys } from '../Icon/Icon.assets'
import { colors } from '../../theme'

import useStyles from './styles'


const Video = (props) => {
  const classes = useStyles(props)
  const {
    className,
    videoId,
    opts,
    onEnd,
    onReady,
    onPause,
    onPlay,
    onClose,
    videoIframeUrl,
    videoPath,
  } = props

  // REFS

  const $container = useRef(null)
  const $video = useRef(null)

  // ANIMATIONS

  const enterAnimation = useCallback(() => {
    gsap.to($container.current, {
      duration: 0.4,
      opacity: 1,
      ease: Power1.easeIn,
    })
  }, [])

  const exitAnimation = useCallback((onCompleteCallback) => {
    gsap.to($container.current, {
      duration: 0.4,
      opacity: 0,
      ease: Power1.easeIn,
      onComplete: onCompleteCallback,
    })
  }, [])

  // HANDLERS

  const handleEnd = useCallback((event) => {
    safeCall(onEnd)
  }, [onEnd])

  const handleReady = useCallback((event) => {
    if (opts?.playerVars?.autoplay === 1) {
      event.target.playVideo()
    }
    safeCall(onReady)
  }, [onReady, opts])

  const handlePause = useCallback((event) => {
    safeCall(onPause)
  }, [onPause])

  const handlePlay = useCallback((event) => {
    safeCall(onPlay)
  }, [onPlay])

  const handleClose = useCallback(() => {
    if (onClose) {
      exitAnimation(onClose)
    }
  }, [exitAnimation, onClose])

  // EFFECTS

  useEffect(() => {
    if ($video?.current) {
      $video.current.play()
    }
  }, [$video])

  useEffect(() => {
    enterAnimation()
  }, [enterAnimation])

  // RETURN

  return (
    <div
      className={cx(classes.container, className)}
      ref={$container}
    >
      {videoId ? (
        <YouTube
          videoId={videoId}
          className={classes.player}
          containerClassName={classes.playerWrapper}
          opts={opts}
          onEnd={handleEnd}
          onReady={handleReady}
          onPause={handlePause}
          onPlay={handlePlay}
        />
      ) : videoIframeUrl && videoIframeUrl !== 'none' ? (
        <div className={classes.playerWrapper}>
          <iframe
            src={videoIframeUrl}
            title="video-playback"
            height="100%"
            width="100%"
            frameBorder="0"
            allow="autoplay; fullscreen"
          />
        </div>
      ) : videoPath && (
        <div className={classes.playerWrapper}>
          <video
            src={`./${videoPath}`}
            title="CLONES - SPRING 22"
            height="100%"
            width="100%"
            controls
            autoPlay
          />
        </div>
      )}
      <Icon
        className={classes.close}
        icon={iconsKeys.Close2}
        onClick={handleClose}
        color={colors.pureWhite}
      />
    </div>
  )
}

export const VideoTemplatePropTypes = {
  className: PropTypes.string,
  videoId: PropTypes.string,
  videoIframeUrl: PropTypes.string,
  videoPath: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  opts: PropTypes.object,
  onEnd: PropTypes.func,
  onReady: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onClose: PropTypes.func,
}

Video.propTypes = VideoTemplatePropTypes

Video.defaultProps = {
  className: null,
  videoId: null,
  videoIframeUrl: null,
  videoPath: null,
  opts: null,
  onEnd: null,
  onReady: null,
  onPause: null,
  onPlay: null,
  onClose: null,
}

export default withMemo()(Video)
