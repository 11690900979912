import { createUseStyles } from 'react-jss'

import { colors, desktop, fonts } from '../../theme'


export default createUseStyles({
  container: {
    opacity: 0,
    zIndex: 100,
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.pureWhite,
    padding: '7rem 2rem 3rem 2rem',
    ...desktop({
      border: '1px solid #DEDEDE',
    }),
    '&.success': {
      padding: '9rem 2rem 9rem 2rem',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  inputlabel: {
    ...fonts.UniversBoldCondensed,
    textTransform: 'uppercase',
    fontSize: '1.6rem',
  },
  input: {
    ...fonts.UniversRoman,
    fontSize: '1.5rem',
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: '1px solid black',
    textAlign: 'center',
    width: '100%',
    maxWidth: '25rem',
    marginTop: '2rem',
    outline: 'none',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '3rem',
    maxWidth: '25rem',
  },
  checkbox: {
    position: 'relative',
    top: '-2px',
    marginRight: '1.5rem',
    border: '1px solid black',
    width: '1.6rem',
    height: '1.6rem',
    display: 'flex',
    cursor: 'pointer',
  },
  checkboxIcon: {
    margin: 'auto',
    color: colors.pureBlack,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  checkboxText: {
    ...fonts.UniversRoman,
    fontSize: '1.2rem',
    maxWidth: 'calc(100% - 3.1rem)',
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  button: {
    marginTop: '4rem',
    '&.disabled': {
      opacity: 0.25,
      pointerEvents: 'none',
    },
  },
  success: {
    ...fonts.UniversBoldCondensed,
    textTransform: 'uppercase',
    fontSize: '1.6rem',
  },
  error: {
    ...fonts.UniversBoldCondensed,
    textTransform: 'uppercase',
    fontSize: '1.6rem',
    color: '#B81818',
  },
  close: {
    position: 'absolute',
    top: '4rem',
    right: '2.5rem',
    ...desktop({
      top: '2.3rem',
      right: '2.3rem',
    }),
    '& svg': {
      width: '2rem',
      height: '2rem',
    },
  },

})
