import { createUseStyles } from 'react-jss'

import { colors, desktop, fonts, aspectRatio } from '../../theme'


export default createUseStyles({
  container: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    minHeight: 'calc(var(--vh, 1vh) * 100)',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    height: '100%',
    width: '100%',
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  headerMenu: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    color: colors.pureBlack,
    fontSize: '1.2rem',
    ...fonts.UniversRoman,
    textTransform: 'uppercase',
    '& > *': {
      margin: '0 1rem',
      cursor: 'pointer',
    },
  },
  headerRight: {
    display: 'none',
    color: colors.pureBlack,
    fontSize: '1.2rem',
    ...fonts.UniversRoman,
    textTransform: 'uppercase',
    '& > *': {
      marginLeft: '2rem',
      cursor: 'pointer',
    },
    ...desktop({
      display: 'flex',
    }),
  },
  iframeContainer: {
    width: '100%',
    margin: '0 auto',
    maxWidth: 'calc((16/9) * (100vh - 21rem))', // keep 16:9 ratio and contain on viewport
  },
  iframeAspectRatio: {
    ...aspectRatio(16, 9),
  },
  waitingScreenContainer: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    maxWidth: 'calc((16/9) * (100vh - 21rem))', // keep 16:9 ratio and contain on viewport
  },
  waitingScreenAspectRatio: {
    ...aspectRatio(16, 9),
  },
  backgroundVideoContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  waitingImage: {
    width: '100%',
    height: '100%',
    img: {
      objectFit: 'cover',
      objectPosition: 'center',
    },
  },
  backgroundVideo: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  videoButton: {
    width: 'auto !important',
    height: 'auto !important',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    top: '50% !important',
    left: '50% !important',
    fontSize: '0.8rem',
    paddingLeft: '0.7rem',
    paddingRight: '0.7rem',
    ...desktop({
      fontSize: '1.2rem',
      paddingLeft: '3rem',
      paddingRight: '3rem',
    }),
  },
  video: {
    zIndex: 100,
  },
  passwordPopup: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    ...desktop({
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: '350px',
      minHeight: '240px',
      height: 'auto',
    }),
  },
  legalLink: {
    ...fonts.UniversRoman,
    fontSize: '1.2rem',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '2rem',
    cursor: 'pointer',
    ...desktop({
      left: '2rem',
      transform: 'none',
    }),
  },
})
