import { createUseStyles } from 'react-jss'

import { aspectRatio } from '../../theme'


export default createUseStyles({
  container: {
    position: 'absolute',
    width: '100%',
    transform: 'translate(-50%,-50%)',
    top: '50% !important',
    left: '50% !important',
    margin: '0 auto',
    maxWidth: 'calc((16/9) * (100vh - 21rem))', // keep 16:9 ratio and contain on viewport
  },
  playerWrapper: {
    ...aspectRatio(16, 9),
  },
  player: {
    width: '100%',
    height: '100%',
  },
  nativePlayer: {
    width: '100%',
    height: '100%',
  },
  nativeVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
  },
  close: {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
  },
})
